:root {
  --app-font-family: "Montserrat", Helvetica, Arial, serif;
}

@import "../assets/fonts/dinNextLt_font/style.css";
@import "./base/bootstrap.scss";
@import "./base/bootstrap-extended.scss";
@import "./base/colors.scss";
@import "./base/components.scss";

// Themes
@import "./base/themes/dark-layout.scss";
@import "./base/themes/bordered-layout.scss";
@import "./base/themes/semi-dark-layout.scss";
//@import "./base/custom-rtl.scss";

// React Specific
@import "react/index";
