@font-face {
	font-family: 'DIN Next LT Arabic';
	src: url('./DINNextLTArabic-Medium.eot');
	src: url('./DINNextLTArabic-Medium.eot?#iefix') format('embedded-opentype'),
		url('./DINNextLTArabic-Medium.woff') format('woff'),
		url('./DINNextLTArabic-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
	font-display: fallback;
}

@font-face {
	font-family: 'DIN Next LT Arabic';
	src: url('./DINNextLTArabic-Regular.eot');
	src: url('./DINNextLTArabic-Regular.eot?#iefix') format('embedded-opentype'),
		url('./DINNextLTArabic-Regular.woff') format('woff'),
		url('./DINNextLTArabic-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: fallback;
}